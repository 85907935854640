<template>
  <v-app>
    <SonyEnvironmentSystemBar />
    <v-app-bar app dark class="bg-black">
      <v-container>
        <img class="logo" alt="Sony" src="../assets/logo-white.png" />
      </v-container>
    </v-app-bar>
    <v-main class="login-bg">
      <v-container class="fill-height">
        <v-row justify="center">
          <v-col lg="6" md="8" sm="10" xs="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer.vue";
import SonyEnvironmentSystemBar from "./SonyEnvironmentSystemBar.vue";

export default {
  name: "LayoutPublicThinCpr",
  components: { SonyEnvironmentSystemBar, Footer },
  async mounted() {
    // clear existing session
    this.$auth.logout();

    this.loading = true;
    let programKey = this.$sonyProgram.getProgramKeyByDomain();
    await this.$api
      .get("/api/programs/byKey/" + programKey + "/public")
      .then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$auth.storeSelectProgram(program),
          this.$auth.storeSelectClient(program.programGroup.client)
        ]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
